.sidebar {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px;
  background: #F3E8F4;
  &__logo {
    display: flex;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 214px;
    background: linear-gradient(180deg, #A66EF1 0%, #6066F1 100%);
    width: calc(100% + 48px);
    left: -24px;
    margin-top: -24px;
    margin-bottom: -168px;
    img {
      max-height: 256px;
      height: 256px;
      max-width: 100%;
      border-radius: 24px;
    }
  }
}

.timer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 36px 50px;
  background: #FFFFFF;
  box-shadow: 0px 45px 60px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  margin-bottom: 24px;
  span {
    font-size: 30px;
    color: #525252;
  }
  strong {
    font-size: 75px;
  }
}

.image {
  border-radius: 16px;
  overflow: hidden;
  img {
    width: 100%;
  }
}

.sun {
  display: flex;
  flex-direction: column;
  padding: 35px;
  background: #FFFFFF;
  box-shadow: 0px 45px 60px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  > span {
    font-size: 30px;
    margin-bottom: 40px;
  }
  &__info {
    &-item {
      display: flex;
      align-items: center;
      &:not(:last-child) {
        margin-bottom: 48px;
      }
      &__image {
        display: flex;
        width: 86px;
        height: 86px;
        margin-right: 30px;
        img {
          max-width: 100%;
          max-height: 100%;
          width: 100%;
        }
      }
      &__info {
        display: flex;
        flex-direction: column;
        strong {
          font-size: 50px;
          line-height: 1;
          margin-bottom: 16px;
        }
        span {
          font-size: 30px;
          line-height: 1;
          color: #525252;
        }
      }
    }
  }
}